<template>
  <div>
    <v-card class="overflow-hidden pt-5 rounded-xl" flat>
      <LearningPlusLogo :height="30"/>

      <div class="pp-hr pp-center" />

      <div class="pa-5">
        <v-toolbar flat>
          <v-icon color="primary" class="mr-2">mdi-account</v-icon>
          <v-toolbar-title class="font-weight-light">
            Your Account
          </v-toolbar-title>
        </v-toolbar>
        <Loading title="Loading your profile"
                 :loading="userProfileLoading"
        ></Loading>
        <ValidationObserver
            v-slot="{ handleSubmit, invalid }">
          <v-form :disabled="userProfileLoading">
            <v-row class="my-1">
              <v-col cols="12" md="6" class="my-0 py-0">
                <ValidationProvider name="GivenName" rules="required|name"
                                    v-slot="{ errors, valid }">
                  <v-text-field
                      solo
                      prepend-inner-icon="mdi-file-account-outline"
                      placeholder="First Name"
                      name="given_name"
                      label="First Name"
                      :error-messages="errors"
                      :success="valid"
                      :disabled="!isAuth0Profile"
                      v-model="given_name"
                      aria-required="true"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" md="6" class="my-0 py-0">
                <ValidationProvider name="FamilyName" rules="required|name"
                                    v-slot="{ errors, valid }">
                  <v-text-field
                      solo
                      placeholder="Family Name"
                      name="family_name"
                      label="Family Name"
                      :error-messages="errors"
                      :success="valid"
                      :disabled="!isAuth0Profile"
                      v-model="family_name"
                      aria-required="true"
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>

            <ValidationProvider name="ContactNumber" rules="required|ukTel"
                                v-slot="{ errors, valid }">
              <v-text-field
                  solo
                  prepend-inner-icon="mdi-card-account-phone"
                  placeholder="Contact Number"
                  name="contact_number"
                  label="Contact Number"
                  :error-messages="errors"
                  :success="valid"
                  v-model="contact_number"
                  aria-required="true"
              ></v-text-field>
            </ValidationProvider>

            <ValidationProvider name="Postcode" rules="required|ukPostcode" v-slot="{ errors, valid }">
              <v-text-field
                  solo
                  prepend-inner-icon="mdi-map-marker"
                  placeholder="Postcode"
                  name="postcode"
                  label="Postcode"
                  v-model="postcode"
                  :error-messages="errors"
                  :success="valid"
                  aria-required="true"
              ></v-text-field>
            </ValidationProvider>

            <v-card outlined class="rounded-xl my-5">
              <v-img src="/images/shades/marketing.jpg" contain />
              <div class="pa-5">
                <p class="pp-title text-capitalize">Don't miss out on life changing opportunities!</p>
                <p class="font-weight-medium">
                  From time to time we’d like to contact you about some of the exciting courses, jobs and
                  opportunities that we have available. If you’d like to hear from us about these potentially
                  life-changing opportunities, select how you would like to be contacted:
                </p>
                <v-row>
                  <v-col>
                    <v-switch v-model="marketing"
                              dense inset color="primary"
                              label="Email" value="email"/>
                  </v-col>

                  <v-col>
                    <v-switch v-model="marketing"
                              dense inset color="primary"
                              label="Phone calls" value="phone_call"/>
                  </v-col>

                  <v-col>
                    <v-switch v-model="marketing"
                              dense inset color="primary"
                              label="Text" value="text"/>
                  </v-col>
                </v-row>
              </div>
            </v-card>
            <div class="text-right">
              <v-btn
                  @click="handleSubmit(onSubmit)"
                  :disabled="invalid"
                  class="rounded-xl pp-scale-1 pa-6"
                  color="primary"
                  :loading="userProfileLoading"
              >
                Save Changes
              </v-btn>
            </div>
          </v-form>
        </ValidationObserver>
      </div>
    </v-card>
    <v-sheet
        v-model="dialog"
        hide-overlay
        persistent
        width="100%"
        color="transparent"
        class="mt-5"
    >
      <Loading title="Loading your profile"
               :loading="userProfileLoading" />

      <v-snackbar v-if="!userProfileLoading" v-model="snackbar" class="sbar">
        Thank you, your profile has been updated.
      </v-snackbar>
    </v-sheet>
  </div>
</template>

<script>
import LearningPlusLogo from "@/components/logos/LearningPlusLogo";
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, numeric, min } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';
import Loading from "@/components/common/Loading";

extend('required', {
  ...required,
  message: 'This field is required',
});
extend('numeric', numeric);
extend('min', min);
extend('name', {
  validate: (value) => {
    const pattern = /^[\p{L} ,.'-]+$/u;
    return pattern.test(value);
  },
  message: 'Must be a valid name format',
});
extend('ukTel', {
  validate: (value) => {
    const pattern = /(\s*\(?0\d{4}\)?(\s*|-)\d{3}(\s*|-)\d{3}\s*)|(\s*\(?0\d{3}\)?(\s*|-)\d{3}(\s*|-)\d{4}\s*)|(\s*(7|8)(\d{7}|\d{3}(-|\s{1})\d{4})\s*)/;
    return pattern.test(value);
  },
  message: 'Must be a valid UK tel number',
});
extend('ukPostcode', {
  validate: (value) => {
    const pattern = /^[a-zA-Z]{1,2}[0-9][0-9A-Za-z]{0,1} {0,1}[0-9][A-Za-z]{2}$/;
    return pattern.test(value);
  },
  message: 'Must be a UK Postcode',
});

export default {
  name: "ProfileForm",
  components: {Loading, LearningPlusLogo, ValidationProvider, ValidationObserver},
  computed: {
    ...mapGetters(['editableUserProfile', 'profileMetadata', 'userProfileLoading', 'isAuth0Profile']),
    // Local computed vars that talk to vuex as that's how the validation works
    contact_number: {
      get() {
        return this.profileMetadata.contact_number;
      },
      set(val) {
        this.updateProfileMetadata({
          contact_number: val,
        });
      },
    },
    family_name: {
      get() {
        return this.editableUserProfile.family_name;
      },
      set(val) {
        this.updateProfile({
          family_name: val,
        });
      },
    },
    given_name: {
      get() {
        return this.editableUserProfile.given_name;
      },
      set(val) {
        this.updateProfile({
          given_name: val,
        });
      },
    },
    marketing: {
      get() {
        return this.profileMetadata.marketing;
      },
      set(val) {
        this.updateProfileMetadata({
          marketing: val,
        });
      },
    },
    postcode: {
      get() {
        return this.profileMetadata.postcode;
      },
      set(val) {
        this.updateProfileMetadata({
          postcode: val,
        });
      },
    },
  },
  data: () => ({
    snackbar: false,
    dialog: false,
  }),
  methods: {
    ...mapActions([
      'putUserProfile',
      'updateProfile',
      'updateProfileMetadata',
    ]),
    onSubmit() {
      this.putUserProfile();
      this.dialog = true;
      this.snackbar = true;
    },
  },
};
</script>
<style>
.v-application--is-ltr .v-text-field .v-input__prepend-inner {
  padding-right: 10px !important;
}
.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none !important;
  border: solid 0.05rem #d3d3d3;
}
.v-date-picker-table .v-btn.v-btn--active {
  color: green !important;
  transform: scale(1.5) !important;
}
.v-input--checkbox.v-input--dense {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.sbar {
  position: unset !important;
  height: 50px !important;
}
</style>
