<template>
  <v-card
      class="rounded-xl pp-scale-1 my-4 launch-card"
      :href="content.path"
      target="_blank"
      rel="noopener noreferrer"
  >
    <v-row class="ma-5 py-5">
      <v-col cols="8" xl="7" md="8">
        <v-img
            :aspect-ratio="16/9"
            :src="content.src"
            :alt="content.alt"
            :height="content.height"
            contain
        />
        <v-divider class="my-xl-5 my-1" />
        <div class="primary--text">
          {{ content.title }}
        </div>

        <div class="text-caption">
          {{ content.text }}
        </div>
      </v-col>

      <v-col cols="4" class="ma-auto pl-5 ml-xl-5">
        <v-btn
            :href="content.path" target="_blank"
            rel="noopener noreferrer"
            fab color="primary"
            class="ma-auto"
        >
          <v-icon>
            mdi-launch
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  name: "LaunchButton",
  props: {
    content: Object,
  },
};
</script>
