<template>
  <div>
    <title>Profile Page</title>
    <v-container class="mt-16 pt-6">
      <v-row>
        <v-col  cols="12"  md="3"  sm="5" lg="3" >
          <v-sheet color="transparent">
            <v-card color="white"
                    class="rounded-xl"
                    flat
            >
              <div class="text-center pa-5 ">
                <v-avatar
                    color="primary"
                    size="68"
                >
                  <v-img :src="$auth.user.picture" />
                </v-avatar>

                <div class="font-weight-medium mt-5 ">
                  Your
                  <span class="primary--text">Learning<span class="grey--text">Plus</span>
                  </span> Profile
                </div>

                <div class="pp-hr pp-center"></div>

                <div class="text-body-2 blue-grey--text mt-5">
                  <v-icon small class="blue-grey--text">
                    mdi-account-circle-outline
                  </v-icon>
                  <div v-if="givenName()">
                    {{ $auth.user.given_name }} {{ $auth.user.family_name }}
                  </div>
                  <div v-else>{{ $auth.user.name }}</div>
                </div>

                <div class="text-body-2 blue-grey--text my-5">
                  <v-icon small class="blue-grey--text">
                    mdi-email-outline
                  </v-icon>
                  {{ $auth.user.email }}
                </div>
              </div>
              <v-row class="px-4 mb-10">
                <v-expansion-panels accordion class="rounded-xl mb-5" flat>
                  <v-expansion-panel  class="mr-2">
                    <v-expansion-panel-header class="ml-1">
                      More Information
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-caption">
                      <v-card outlined class="user-bl pa-3 rounded-xl">User Profile:<br />
                        {{ $auth.user['http://p3.peopleplus.truenorthit.co.uk/roles'] }}
                      </v-card>
                      <v-card outlined class="user-bl pa-3 rounded-xl">
                        User ID:<br /> {{ $auth.user.sub }}
                      </v-card>
                      <v-card outlined class="user-bl rounded-xl px-2 py-3">
                        First Name:<br /> {{ $auth.user.given_name }}
                      </v-card>
                      <v-card outlined class="user-bl rounded-xl px-2 py-3">
                        Last Name:<br /> {{ $auth.user.family_name }}
                      </v-card>
                      <v-card outlined class="user-bl rounded-xl px-2 py-3">
                        Name:<br /> {{ $auth.user.name }}
                      </v-card>
                      <v-card outlined class="user-bl rounded-xl px-2 py-3">
                        Email:<br /> {{ $auth.user.email }}
                      </v-card>
                      <v-chip outlined class="user-bl">Email Verified: {{ $auth.user.email_verified }}</v-chip>
                      <div v-if="$auth.user['https://peopleplus.co.uk/geoip']">
                        <v-chip outlined class="user-bl">Country:
                          {{ $auth.user['https://peopleplus.co.uk/geoip'].country_name }}
                        </v-chip>
                        <v-chip outlined class="user-bl">Time Zone:
                          {{ $auth.user['https://peopleplus.co.uk/geoip'].time_zone}}
                        </v-chip>
                        <v-chip outlined class="user-bl">Continent:
                          {{ $auth.user['https://peopleplus.co.uk/geoip'].continent_code }}
                        </v-chip>
                      </div>
                      <v-chip outlined class="user-bl pa-6">Last Updated:<br />{{ $auth.user.updated_at }} </v-chip>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <v-divider />
                  <v-expansion-panel>
                    <v-expansion-panel-header>
                      Advanced
                    </v-expansion-panel-header>
                    <v-expansion-panel-content class="text-body-2">
                      <v-sheet class="ma-6">
                        <div>
                          For support and developers
                        </div>
                        <v-divider class="py-3" />
                        {{ JSON.stringify($auth.user, null, 2) }}
                      </v-sheet>

                    </v-expansion-panel-content>
                  </v-expansion-panel>

                </v-expansion-panels>
              </v-row>
            </v-card>
          </v-sheet>
        </v-col>

        <v-col cols="12" md="6" sm="7" lg="6">
          <v-sheet color="transparent">
            <ProfileForm />
          </v-sheet>
        </v-col>

        <v-col cols="12" md="3" sm="5" lg="3">
          <v-sheet color="transparent">
            <div class="font-weight-medium mt-5 ">
              <v-icon class="mx-2">
                mdi-launch
              </v-icon>
              Shortcuts
            </div>

            <div class="pp-hr"></div>
            <LaunchButton :content="profileLearnerUser" />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import ProfileForm from "@/components/ProfileForm";
import LaunchButton from "@/components/buttons/LaunchButton";
import { hasRoleMixin } from "@/mixin/hasRoleMixin";

export default {
  name: "Profile",
  metaInfo: {
    title: 'LearningPlus Profile',
    meta:[
      { description: 'Join thousands of others who have used our digital learning system to keep up in a fast-paced jobs market. Whether you\'re looking to take the next step in your career or train in a new field, we have an extensive range of courses that can help you achieve this. ' },
    ],
  },
  mixins: [hasRoleMixin],
  components: {LaunchButton, ProfileForm},
  computed: {
    ...mapGetters(['profileLearnerUser']),
  },
  methods: {
    givenName() {
      return this.$auth.user.given_name;
    },
  },
};
</script>

<style scoped>
.user-bl {
  width: 100%;
  margin: 4px;
  font-size: small;
}
</style>
